import React, {ReactNode} from "react";
import {motion} from "framer-motion";
import AssignmentTurnedInIcon from '@mui/icons-material/AssignmentTurnedIn';
import HouseSidingIcon from '@mui/icons-material/HouseSiding';
import TipsAndUpdatesIcon from '@mui/icons-material/TipsAndUpdates';
import InfoIcon from '@mui/icons-material/Info';
import PersonIcon from '@mui/icons-material/Person';

type InfoType = 'info' | 'tip'

interface InfoProps {
    width?: number
    children: ReactNode
    icon?: 'house' | 'file' | 'tip' | 'info' | 'person'
    type?: InfoType
    onClick?: () => void
}

const Info = (props: InfoProps) => {
    const width = props.width || 320

    const getBackground = (type: InfoType | undefined) => {
        switch (type) {
            case 'tip': return '#e4b455'//'linear-gradient(0.375turn, #e4b455, #c49933)'
            default: return '#ffffff'//'linear-gradient(0.375turn, #f2f4f6, #b0c0d0)'
        }
    }

    const getIconColor = (type: InfoType | undefined) => {
        switch (type) {
            case 'tip': return '#ffdd00'
            default: return '#00204040'
        }
    }

    return (
        <motion.div
            onClick={props.onClick}
            style={{
                width: width + 16,
                marginLeft: -8,
                marginRight: -8,
                marginBottom: 4}}
            initial={{
                opacity: 0
            }}
            animate={{
                opacity: 1,
                transition: {
                    ease: 'linear', duration: .5
                }
            }}>
            <div style={{
                position: 'relative',
                width: width - 48 + 16,
                height: '100%',
                top: 0,
                background: getBackground(props.type), //'linear-gradient(0.375turn, #f2f4f6, #b0c0d0)', //#e2e6eb, #e2e6eb)',
                paddingLeft: 50,
                borderRadius: 8}}
            >
                <div style={{position: 'absolute', left: 0, top: 8}}>
                    {
                        ((!props.icon || props.icon === 'file') && <AssignmentTurnedInIcon sx={{
                            fontSize: 32,
                            color: getIconColor(props.type),
                            marginLeft: 1.2,
                            marginTop: .3
                        }}/>) || (props.icon === 'house' && <HouseSidingIcon sx={{
                            fontSize: 32,
                            color: getIconColor(props.type),
                            marginLeft: 1.2,
                            marginTop: .3
                        }}/>) || (props.icon === 'tip' && <TipsAndUpdatesIcon sx={{
                            fontSize: 32,
                            color: getIconColor(props.type),
                            marginLeft: 1.2,
                            marginTop: .3
                        }}/>) || (props.icon === 'info' && <InfoIcon sx={{
                            fontSize: 32,
                            color: getIconColor(props.type),
                            marginLeft: 1.2,
                            marginTop: .3
                        }}/>) || (props.icon === 'person' && <PersonIcon sx={{
                            fontSize: 32,
                            color: getIconColor(props.type),
                            marginLeft: 1.2,
                            marginTop: .3
                        }}/>)
                    }
                </div>
                <div style={{paddingTop: 6, fontSize: 14, paddingBottom: 8, paddingRight: 8, textAlign: 'left'}}>
                    {props.children}
                </div>
            </div>
        </motion.div>)
}

export default Info;