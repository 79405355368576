import React, {useEffect, useState} from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App, {getVariableOrNull, QR} from './App';
import reportWebVitals from './reportWebVitals';

import {
    BrowserRouter,
    Routes,
    Route
} from "react-router-dom";
import Landing from "./Landing";
import {Grid} from "@mui/material";

const PseudoRoutes = () => {
    const [mode, setMode] = useState<string>("landing");

    useEffect(() => {
        const category = getVariableOrNull("Kategorie")
        if (category) setMode("qr");
        else setMode("landing")
    }, []);

    return <div className={"bg"}>
        {mode === 'landing' && <Landing
        clickCheck={() => setMode("check")}
        clickQR={() => {
            setMode("qr");
            window.scrollTo(0, 0);
        }}/>}
        {mode === 'check' && <App />}
        {mode === 'qr' && <QR />}
    </div>
}

ReactDOM.render(
  <React.StrictMode>
      <div className={"bg"}>
          <PseudoRoutes/>
      </div>
      {/*<div style={{height: '100vh', width: '100%', zIndex: 5}}><App /></div>*/}
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
