import {Fade, TextField, Zoom} from "@mui/material";
import {makeStyles} from '@mui/styles';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import EditIcon from '@mui/icons-material/Edit';
import React, {useEffect} from "react";
import {AnimatePresence, motion} from "framer-motion";
import {ComponentProps} from "./Options";
import {getVariableOrNull} from "../App";
import {checkedColor, EditLogo, uncheckedColor} from "./TileSelect";

const categories = [
    "Gartenhaus",
    "Solaranlage",
    "Sanierung",
    "Balkon",
    "Einfamilienhaus"
];

interface TextInputProps extends ComponentProps{
    question?: string
    default?: string
    unit?: string
}

const useStyles = makeStyles({
    underline: {
        "&&&:before": {
            color: '#ffffff'
        },
        "&&:after": {
            color: '#ffffff'
        }
    }
});

export const checkedColorAlpha = 'transparent' //'linear-gradient(0.375turn, #0070e0c8, #0050a0c8)' //'linear-gradient(0.375turn, #0070e0c8, #0050a0c8)'
export const resultColorAlpha = 'linear-gradient(0.375turn, #ffffff, #d0d6dcc8)'

const TextInput = (props: TextInputProps) => {
    const defaultValue = props.default || getVariableOrNull(props.name)
    const [checked, setChecked] = React.useState<boolean>(defaultValue != null);
    const [value, setValue] = React.useState<string>("");
    const classes = useStyles();

    const uncheckedColorAlpha = 'transparent' //'#ffffff60'

    const width = props.width || 320
    //const condensed = props.condensed || defaultValue
    const [condensed, setCondensed] = React.useState<boolean>(props.condensed || (defaultValue !== undefined));
    useEffect(
        () => {
            setCondensed(props.condensed || (defaultValue !== undefined))
        },
        [props.condensed, defaultValue],
    );

    return (
        <motion.div
            onClick={(e) => {
                if (condensed) {
                    setCondensed(false)
                    setChecked(false)
                    props.set && props.set(props.name, undefined)
                }
            }}
            onBlur={(e) => {
                if (value.split("x").length === 3) {
                    setChecked(true)
                    setCondensed(true)
                }
            }}
            style={{width: width}}
            initial={{
                opacity: 0
            }}
            animate={{
                opacity: 1,
                transition: {
                    ease: 'linear', duration: .5
                }
            }}>
            <AnimatePresence>{props.question && !checked && <motion.div style={{
                position: 'relative',
                left: 0,
                maxWidth: 'calc(100% - 40px)',
                marginTop: 8,
                marginBottom: -4,
                marginLeft: -8,
                textAlign: 'left',
                color: '#000',
                fontSize: 14,
                padding: 4,
                paddingLeft: 8,
                background: '#00306020',
                borderRadius: '0 8px 8px 8px'
            }} initial={{
                scale: 0,
                opacity: 0
            }} animate={{
                scale: 1,
                opacity: 1,
                transition: {
                    ease: 'linear',
                    duration: 0
                }
            }} exit={{
                scale: 0,
                opacity: 0,
                height: 0,
                transition: {
                    duration: .25
                }
            }}>{props.question}</motion.div>}</AnimatePresence>
            <div style={{position: 'relative', width: '100%', height: 58 - (condensed ? 28 : 0)}}>
                <div style={{
                    position: 'relative',
                    width: width + 16, //390,
                    height: 54 - (condensed ? 20 : 0),
                    background: checked ? checkedColorAlpha : uncheckedColorAlpha, //'#ffffff77',
                    borderRadius: 0,
                    marginLeft: -8,
                    marginRight: -8,
                    top: 0
                }}/>
                <div style={{position: 'absolute', left: 8, top: 5}}>
                    {condensed && <CheckCircleIcon sx={{fontSize: 24, color: checkedColor}}/>}
                </div>
                <div style={{position: 'absolute', left: 40, top: 8}}>
                    {!condensed ? <><TextField
                        style={{
                            width: (width - 42) / 3 - 5, background: '#ffffff', //checked ? '#ffffff' : '#ffffffbb',checked ? '#ffffff' : '#ffffffbb',
                            borderRadius: 4
                        }} //332
                        size={"small"}
                        type={"number"}
                        value={defaultValue}
                        //label={checked ? "" : "Kategorie"}
                        label=""
                        InputProps={{
                            autoComplete:"off",
                            startAdornment: <span style={{
                                color: '#404040',
                                marginLeft: -8,
                                marginRight: 6,
                                //fontSize: 14,
                                //lineHeight: 1,
                                textAlign: 'left'
                            }}>{"L: "}</span>,
                            endAdornment: null
                        }}
                        InputLabelProps={{shrink: false}}
                        onChange={
                            (event) => {
                                //if (event.target.value && event.target.value !== "") setChecked(true);
                                //else setChecked(false);
                                //if (props.set) props.set(props.name, event.target.value)
                                if (event.target.value.length < 3) return;
                                setValue(event.target.value);
                            }
                        }
                    />x<TextField
                        style={{
                            width: (width - 42) / 3 - 5, background: '#ffffff', //checked ? '#ffffff' : '#ffffffbb',checked ? '#ffffff' : '#ffffffbb',
                            borderRadius: 4
                        }} //332
                        size={"small"}
                        type={"number"}
                        value={defaultValue}
                        //label={checked ? "" : "Kategorie"}
                        label=""
                        InputProps={{
                            autoComplete:"off",
                            startAdornment: <span style={{
                                color: '#404040',
                                marginLeft: -8,
                                marginRight: 6,
                                //fontSize: 14,
                                //lineHeight: 1,
                                textAlign: 'left'
                            }}>{"B: "}</span>,
                            endAdornment: null
                        }}
                        InputLabelProps={{shrink: false}}
                        onChange={
                            (event) => {
                                //if (event.target.value && event.target.value !== "") setChecked(true);
                                //else setChecked(false);
                                //if (props.set) props.set(props.name, event.target.value)
                                if (event.target.value.length < 3) return;
                                setValue(value + "x" + event.target.value);
                            }
                        }
                    />x<TextField
                        style={{
                            width: (width - 42) / 3 - 5, background: '#ffffff', //checked ? '#ffffff' : '#ffffffbb',checked ? '#ffffff' : '#ffffffbb',
                            borderRadius: 4
                        }} //332
                        size={"small"}
                        type={"number"}
                        value={defaultValue}
                        //label={checked ? "" : "Kategorie"}
                        label=""
                        InputProps={{
                            autoComplete:"off",
                            startAdornment: <span style={{
                                    color: '#404040',
                                    marginLeft: -8,
                                    marginRight: 6,
                                    //fontSize: 14,
                                    //lineHeight: 1,
                                    textAlign: 'left'
                                }}>{"H: "}</span>,
                            endAdornment: null
                        }}
                        InputLabelProps={{shrink: false}}
                        onChange={
                            (event) => {
                                //if (event.target.value && event.target.value !== "") setChecked(true);
                                //else setChecked(false);
                                if (event.target.value.length < 3) return;
                                setValue(value + "x" + event.target.value);
                                if (props.set) props.set(props.name, value + "x" + event.target.value)
                            }
                        }
                    /></> : <div style={{color: '#00204060', marginTop: -1, fontSize: 16}}>{props.name + ": " + (defaultValue || value) + props.unit}</div>}
                </div>
            </div>
            {checked && props.then}
        </motion.div>)
}

export default TextInput/* () => <Autocomplete
        disablePortal
        blurOnSelect
        id="combo-box-demo"
        options={categories}
        sx={{width: 300}}
        renderInput={(params) => <TextField {...params} label="Kategorie"/>}
    />*/
