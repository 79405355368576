import {makeStyles} from '@mui/styles';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import React, {useEffect} from "react";
import {AnimatePresence, motion} from "framer-motion";
import {ComponentProps} from "./Options";
import {getVariableOrNull} from "../App";
import {textStyleSmall} from "../Landing";
import {checkedColorAlpha} from "./TextInput";

const categories = [
    "Gartenhaus",
    "Solaranlage",
    "Sanierung",
    "Balkon",
    "Einfamilienhaus"
];

interface TextInputProps extends ComponentProps {
    question?: string
    default?: string
    unit?: string
}

const useStyles = makeStyles({
    underline: {
        "&&&:before": {
            color: '#ffffff'
        },
        "&&:after": {
            color: '#ffffff'
        }
    }
});

const tileStyleLight = {
    position: 'absolute',
    width: 106, //390,
    height: 106,
    background: '#ffffff',
    boxShadow: '2px 2px 32px -4px #00204016'
} as React.CSSProperties;

const tileBG = (i: number, j: number) => {return {
    background: 'url(t' + i + j + '.jpg)',
    backgroundSize: '80px 80px', //'344px 233px',
    backgroundColor: '#ffffff',
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center'
} as React.CSSProperties;}

export const checkedColor = '#0050a8'//'#00306020' //'#00ff00'
export const uncheckedColor = 'rgba(200, 200, 200, 0.5)'

export const CheckLogo = () => <svg version="1.1" id="Ebene_1" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px"
                                    width="50px" height="50px" viewBox="0 0 50 50" enableBackground="new 0 0 50 50">
    <path fill="none" stroke="#0050A0" strokeWidth="2" strokeMiterlimit="10" d="M32.5,30.354c0,1.104-0.896,2-2,2H19
	c-1.104,0-2-0.896-2-2v-11.5c0-1.104,0.896-2,2-2h11.5c1.104,0,2,0.896,2,2V30.354z"/>
    <polygon fill="#0050A0" stroke="#FFFFFF" strokeWidth="1.25" strokeMiterlimit="10" points="19.042,21.854 27.573,30.375
	41.775,16.155 38.932,13.314 27.57,24.69 21.882,19.011 "/>
</svg>

export const EditLogo = () => <svg version="1.1" id="Ebene_1" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px"
                                   width="50px" height="50px" viewBox="0 0 50 50" enableBackground="new 0 0 50 50">
    <path fill="#ffffff" stroke="#E0E0E0" strokeWidth="2" strokeMiterlimit="10" d="M32.537,30.921c0,1.104-0.895,2-2,2h-11.5
	c-1.104,0-2-0.896-2-2v-11.5c0-1.104,0.896-2,2-2h11.5c1.105,0,2,0.896,2,2V30.921z"/>
    <g>

        <g>
            <polygon fill="#FFC064" points="24.408,22.258 23.366,18.839 39.273,2.931 41.504,5.161 		"/>

            <rect x="32.225" y="3.256" transform="matrix(0.707 0.7072 -0.7072 0.707 20.9873 -19.9669)" fill="#FF9D00"
                  width="4.731" height="24.178"/>
            <polygon fill="#DD8300" points="31.131,26.593 27.711,25.552 44.809,8.455 47.037,10.685 		"/>
            <path fill="#EAD7AC" d="M23.366,18.839c0,0,0.297,1.338,0.707,1.747c0.781,0.78,1.635,0.371,1.635,0.371s-0.297,1.338,0.744,2.379
			c1.04,1.041,2.526,0.967,2.526,0.967s-0.482,0.631,0.372,1.486c0.483,0.483,1.78,0.804,1.78,0.804l-10.812,3.174"/>
            <polygon fill="#0050A0" points="20.346,29.719 21.646,24.85 25.099,28.332 		"/>
        </g>
    </g>
</svg>

const TileSelect = (props: TextInputProps & {target: 'Balkon' | 'Gartenhaus'}) => {
    const defaultValue = props.default || getVariableOrNull(props.name)
    const [checked, setChecked] = React.useState<boolean>(defaultValue != null);
    const [value, setValue] = React.useState<string>("");
    const classes = useStyles();

    const uncheckedColorAlpha = 'transparent' //'#ffffff60'

    const width = props.width || 320
    //const condensed = props.condensed || defaultValue
    const [condensed, setCondensed] = React.useState<boolean>(props.condensed || (defaultValue !== undefined));
    useEffect(
        () => {
            setCondensed(props.condensed || (defaultValue !== undefined))
        },
        [props.condensed, defaultValue],
    );

    const tiles = "Abriss, Baumfällung, Balkon, Garage, Gartenhaus, Neubau, Sanierung, Solaranlage, Zaun"
        .replace(" ", "")
        .split(",");

    const overlay = <div style={{position: 'absolute', left: 0, top: 0, width: '100%', height: '100%', background: '#ffffff75'}}/>

    return (
        <motion.div
            /*
            onClick={(e) => {
                if (condensed) {
                    setCondensed(false)
                    setChecked(false)
                    props.set && props.set(props.name, undefined)
                }
            }}
            */
            style={{width: width, textAlign: 'left'}}
            initial={{
                opacity: 0
            }}
            animate={{
                opacity: 1,
                transition: {
                    ease: 'linear', duration: .5
                }
            }}>
            {/*<AnimatePresence>{props.question && !checked && <motion.div style={{
                position: 'relative',
                left: 0,
                display: 'inline-block',
                maxWidth: 'calc(100% - 40)',
                marginTop: 2,
                marginBottom: -4,
                textAlign: 'left',
                color: '#fff',
                fontSize: 14,
                background: '#ff0000',
                borderRadius: '0px 12px 12px 12px',
                padding: 4,
                paddingLeft: 12,
                paddingRight: 12,
                marginLeft: -4,
            }} initial={{
                scale: 0,
                opacity: 0
            }} animate={{
                opacity: 1,
                scale: 1,
                transition: {
                    duration: .5
                }
            }} exit={{
                scale: 0,
                opacity: 0
            }}>{props.question}</motion.div>}</AnimatePresence>*/}
            <AnimatePresence>{props.question && !checked && <motion.div style={{
                position: 'relative',
                left: 0,
                maxWidth: 'calc(100% - 40px)',
                marginTop: 8,
                marginBottom: -4,
                marginLeft: -8,
                display: 'inline-block',
                textAlign: 'left',
                color: '#000',
                fontSize: 14,
                padding: 4,
                paddingLeft: 8,
                background: '#00306020',
                borderRadius: '0 8px 8px 8px'
            }} initial={{
                scale: 0,
                opacity: 0
            }} animate={{
                scale: 1,
                opacity: 1,
                transition: {
                    duration: .25
                }
            }} exit={{
                scale: 0,
                opacity: 0,
                height: 0,
                transition: {
                    duration: 0
                }
            }}>{props.question}</motion.div>}</AnimatePresence>

            <motion.div style={{position: 'relative', width: '100%', height: 258 - (condensed ? 220 : 0)}}
                        animate={{height: 58 - (condensed ? 28 : 0), transition: {ease: 'linear'}}}>
                <div style={{
                    position: 'relative',
                    width: width + 16, //390,
                    height: 54 - (condensed ? 20 : 0),
                    background: checked ? checkedColorAlpha : uncheckedColorAlpha, //'#ffffff77',
                    borderRadius: '8px 8px 0px 0px',
                    marginLeft: -8,
                    marginRight: -8,
                    top: 0
                }}/>
                <div style={{position: 'absolute', left: 8, top: 5}}>
                    {condensed && <CheckCircleIcon sx={{fontSize: 24, color: checkedColor}}/>}
                </div>
                <div style={{position: 'absolute', left: 40, top: 8}}>
                    {!condensed && !checked ?
                        <div style={{position: 'absolute', left: -40}}>
                            <motion.div
                                style={{
                                    ...tileStyleLight,
                                    ...tileBG(0, 0),
                                    textAlign: 'center',
                                    left: -4,
                                    top: 0,
                                    borderRadius: '8px 0px 0px 0px'
                                }}
                                initial={{scale: 0}}
                                exit={{scale: 0}}
                                animate={{scale: 1, transition: {duration: .5, delay: 0}}}
                                whileTap={{scale: .9}}>{overlay}
                                <div style={{
                                    ...textStyleSmall,
                                    position: 'absolute',
                                    bottom: 4,
                                    width: '100%'
                                }}>{tiles[0]}
                                </div>
                            </motion.div>
                            <motion.div
                                style={{...tileStyleLight,
                                    ...tileBG(0, 1), textAlign: 'center', left: 107, top: 0}}
                                initial={{scale: 0}}
                                exit={{scale: 0}}
                                animate={{scale: 1, transition: {duration: .5, delay: .2}}}
                                whileTap={{scale: .9}}>{overlay}
                                <div
                                    style={{
                                        ...textStyleSmall,
                                        position: 'absolute',
                                        bottom: 4,
                                        width: '100%'
                                    }}>{tiles[1]}
                                </div>
                            </motion.div>
                            <motion.div
                                style={{
                                    ...tileStyleLight,
                                    ...tileBG(0, 2),
                                    textAlign: 'center',
                                    left: 218,
                                    top: 0,
                                    borderRadius: '0px 8px 0px 0px'
                                }}
                                initial={{scale: 0}}
                                exit={{scale: 0}}
                                animate={{scale: 1, transition: {duration: .5, delay: .4}}}
                                whileTap={{scale: .9}}
                                onClick={(event) => {
                                    if (props.target !== 'Balkon') return;
                                    setChecked(true);
                                    setCondensed(true);
                                    if (props.set) props.set(props.name, "Balkon")
                                    setValue("Balkon");
                                }}>{overlay}
                                <div
                                    style={{
                                        ...textStyleSmall,
                                        position: 'absolute',
                                        bottom: 4,
                                        width: '100%'
                                    }}>{tiles[2]}
                                </div>
                            </motion.div>

                            <motion.div
                                style={{
                                    ...tileStyleLight,
                                    ...tileBG(1, 0),
                                    textAlign: 'center',
                                    left: -4,
                                    top: 111
                                }}
                                initial={{scale: 0}}
                                exit={{scale: 0}}
                                animate={{scale: 1, transition: {duration: .5, delay: .6}}}
                                whileTap={{scale: .9}}>{overlay}
                                <div style={{
                                    ...textStyleSmall,
                                    position: 'absolute',
                                    bottom: 4,
                                    width: '100%'
                                }}>{tiles[3]}
                                </div>
                            </motion.div>
                            <motion.div
                                style={{...tileStyleLight,
                                    ...tileBG(1, 1),
                                    textAlign: 'center', left: 107, top: 111}}
                                initial={{scale: 0}}
                                exit={{scale: 0}}
                                animate={{scale: 1, transition: {duration: .5, delay: .8}}}
                                whileTap={{scale: .9}}
                                onClick={(event) => {
                                    if (props.target !== 'Gartenhaus') return;
                                    setChecked(true);
                                    setCondensed(true);
                                    if (props.set) props.set(props.name, "Gartenhaus")
                                    setValue("Gartenhaus");
                                }}>{overlay}
                                <div style={{
                                    ...textStyleSmall,
                                    position: 'absolute',
                                    bottom: 4,
                                    width: '100%'
                                }}>{tiles[4]}
                                </div>
                            </motion.div>
                            <motion.div
                                style={{
                                    ...tileStyleLight,
                                    ...tileBG(1, 2),
                                    textAlign: 'center',
                                    left: 218,
                                    top: 111
                                }}
                                initial={{scale: 0}}
                                exit={{scale: 0}}
                                animate={{scale: 1, transition: {duration: .5, delay: 1}}}
                                whileTap={{scale: .9}}>{overlay}
                                <div style={{
                                    ...textStyleSmall,
                                    position: 'absolute',
                                    bottom: 4,
                                    width: '100%'
                                }}>{tiles[5]}
                                </div>
                            </motion.div>

                            <motion.div
                                style={{
                                    ...tileStyleLight,
                                    ...tileBG(2, 0),
                                    textAlign: 'center',
                                    left: -4,
                                    top: 222,
                                    borderRadius: '0px 0px 0px 8px '
                                }}
                                initial={{scale: 0}}
                                exit={{scale: 0}}
                                animate={{scale: 1, transition: {duration: .5, delay: 1.2}}}
                                whileTap={{scale: .9}}>{overlay}
                                <div style={{
                                    ...textStyleSmall,
                                    position: 'absolute',
                                    bottom: 4,
                                    width: '100%'
                                }}>{tiles[6]}
                                </div>
                            </motion.div>
                            <motion.div
                                style={{...tileStyleLight,
                                    ...tileBG(2, 1),
                                    textAlign: 'center', left: 107, top: 222}}
                                initial={{scale: 0}}
                                exit={{scale: 0}}
                                animate={{scale: 1, transition: {duration: .5, delay: 1.4}}}
                                whileTap={{scale: .9}}>{overlay}
                                <div style={{
                                    ...textStyleSmall,
                                    position: 'absolute',
                                    bottom: 4,
                                    width: '100%'
                                }}>{tiles[7]}
                                </div>
                            </motion.div>
                            <motion.div
                                style={{
                                    ...tileStyleLight,
                                    ...tileBG(2, 2),
                                    textAlign: 'center',
                                    left: 218,
                                    top: 222,
                                    borderRadius: '0px 0px 8px 0px'
                                }}
                                initial={{scale: 0}}
                                exit={{scale: 0}}
                                animate={{scale: 1, transition: {duration: .5, delay: 1.6}}}
                                whileTap={{scale: .9}}>{overlay}
                                <div style={{
                                    ...textStyleSmall,
                                    position: 'absolute',
                                    bottom: 4,
                                    width: '100%'
                                }}>{tiles[8]}
                                </div>
                            </motion.div>
                        </div> : <div style={{
                            color: '#00204060',
                            marginTop: -1,
                            fontSize: 17
                        }}>{props.name + ": " + (defaultValue || value) + props.unit}</div>}
                </div>
            </motion.div>
            {checked && props.then}
        </motion.div>)
}

export default TileSelect/* () => <Autocomplete
        disablePortal
        blurOnSelect
        id="combo-box-demo"
        options={categories}
        sx={{width: 300}}
        renderInput={(params) => <TextField {...params} label="Kategorie"/>}
    />*/
