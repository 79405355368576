import Autocomplete from '@mui/material/Autocomplete';
import {MenuItem, Select, TextField} from "@mui/material";
import {makeStyles} from '@mui/styles';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import EditIcon from '@mui/icons-material/Edit';
import React from "react";
import {AnimatePresence, motion} from "framer-motion";
import {checkedColorAlpha} from "./TextInput";
import {getVariableOrNull} from "../App";
import {checkedColor, CheckLogo, EditLogo, uncheckedColor} from "./TileSelect";
import ArrowCircleRightIcon from "@mui/icons-material/ArrowCircleRight";

const categories = [
    "Gartenhaus",
    "Solaranlage",
    "Terrasse",
    "Balkon",
    "Einfamilienhaus"
];

export interface ComponentProps {
    onClick?: () => void
    name: string
    question?: string
    hint?: string
    width?: number
    then?: JSX.Element[]
    condensed?: boolean
    set?: (key: string, value: string | undefined) => void
}

interface CheckedOptionProps extends ComponentProps{
    options: string[]
    default?: string
}

const useStyles = makeStyles({
    underline: {
        "&&&:before": {
            color: '#ffffff'
        },
        "&&:after": {
            color: '#ffffff'
        }
    }
});

export const CheckedOption = (props: CheckedOptionProps) => {
    const defaultValue = props.default || getVariableOrNull(props.name)
    const [checked, setChecked] = React.useState<boolean>(defaultValue != null);
    const [selected, setSelected] = React.useState<string>("");
    const classes = useStyles();

    const uncheckedColorAlpha = 'transparent'//'#ffffff60'

    const width = props.width || 320
    const [condensed, setCondensed] = React.useState<boolean>(props.condensed || (defaultValue !== undefined));

    return (
        <motion.div
            style={{width: width}}
            initial={{
                opacity: 0
            }}
            animate={{
                opacity: 1,
                transition: {
                    ease: 'linear', duration: .4
                }
            }}>
            <AnimatePresence>{props.question && !checked && <motion.div style={{
                position: 'relative',
                left: 0,
                maxWidth: 'calc(100% - 40px)',
                marginTop: 8,
                marginBottom: -4,
                marginLeft: -8,
                textAlign: 'left',
                color: '#000',
                fontSize: 14,
                padding: 4,
                paddingLeft: 8,
                background: '#00306020',
                borderRadius: '0 8px 8px 8px'
            }} initial={{
                opacity: 0
            }} animate={{
                opacity: 1,
                transition: {
                    ease: 'linear',
                    duration: 0
                }
            }} exit={{
                opacity: 0,
                height: 0,
                transition: {
                    duration: .25
                }
            }}>{props.question}</motion.div>}</AnimatePresence>
            <div style={{position: 'relative', width: '100%', height: 58 - (condensed ? 28 : 0)}}>
                <div style={{
                    position: 'relative',
                    width: width + 16, //390,
                    height: 54 - (condensed ? 20 : 0),
                    background: checked ? checkedColorAlpha : uncheckedColorAlpha, //'#ffffff77',
                    borderRadius: props.name === 'PLZ' ? '0px 0px 8px 8px' : 0,
                    marginLeft: -8,
                    marginRight: -8,
                    top: 0
                }}/>
                <div style={{position: 'absolute', left: 8, top: 5}}>
                    {condensed && (props.hint !== undefined ?
                        <ArrowCircleRightIcon sx={{fontSize: 24, color: '#ff0000'}}/> :
                        <CheckCircleIcon sx={{fontSize: 24, color: checkedColor}}/>)}
                </div>
                <div style={{position: 'absolute', left: 40, top: 8}}>
                    {!condensed ? <Autocomplete
                        disablePortal
                        defaultValue={defaultValue}
                        blurOnSelect
                        id="combo-box-demo"
                        options={props.options}
                        style={{width: width - 42}} //332
                        size={"small"}
                        renderInput={(params) =>
                            <TextField
                                {...params}
                                //label={checked ? "" : "Kategorie"}
                                label=""/*{
                                    <><span style={{
                                        color: '#555555'
                                    }}>Kategorie: </span><span style={{
                                        color: '#000000'
                                    }}>Gartenhaus</span></>
                                }*/
                                /*{<div style={{
                                        color: '#555555',
                                        background: checked ? '#00ff00' : '#ffffff',
                                        paddingLeft: 6,
                                        paddingRight: 6,
                                        borderRadius: 8
                                    }}>Kategorie</div>}*/
                                style={{
                                    background: '#ffffff', //checked ? '#ffffff' : '#ffffffbb',
                                    borderRadius: 4
                                }}
                                InputProps={{
                                    ...params.InputProps,
                                    startAdornment: <span style={{
                                        color: '#404040'
                                    }}>{props.name + ": "}</span>
                                }}
                                InputLabelProps={{shrink: false}}
                                onBlur={(e) => {
                                    if (checked) setCondensed(true)
                                }}
                            />
                        }
                        onChange={
                            (event, value, reason) => {
                                if (value) {
                                    setChecked(true);
                                    setSelected(value);
                                    setCondensed(true);
                                    if (props.set) props.set(props.name, value)
                                }
                                else setChecked(false);
                            }
                        }
                        onBlur={(e) => {
                            if (checked) setCondensed(true)
                        }}
                    /> : <div style={{color: '#00204060', marginTop: -1, fontSize: 16}}>{props.name + ": " + (defaultValue || selected)}</div>}
                </div>
            </div>
            {checked && selected !== "nein" && props.then}
        </motion.div>)
}

const CheckedSelect = (props: CheckedOptionProps) => {
    const defaultValue = props.default || getVariableOrNull(props.name)
    const [checked, setChecked] = React.useState<boolean>(defaultValue != null);
    const [selected, setSelected] = React.useState<string>("");
    const classes = useStyles();

    const uncheckedColorAlpha = 'transparent'//'#ffffff60'

    const width = props.width || 320
    const [condensed, setCondensed] = React.useState<boolean>(props.condensed || (defaultValue !== undefined));

    return (
        <motion.div
            style={{width: width}}
            initial={{
                opacity: 0
            }}
            animate={{
                opacity: 1,
                transition: {
                    ease: 'linear', duration: .4
                }
            }}>
            <AnimatePresence>{props.question && !checked && <motion.div style={{
                position: 'relative',
                left: 0,
                maxWidth: 'calc(100% - 40px)',
                marginTop: 8,
                marginBottom: -4,
                marginLeft: -8,
                textAlign: 'left',
                color: '#000',
                fontSize: 14,
                padding: 4,
                paddingLeft: 8,
                background: '#00306020',
                borderRadius: '0 8px 8px 8px'
            }} initial={{
                opacity: 0
            }} animate={{
                opacity: 1,
                transition: {
                    ease: 'linear',
                    duration: 0
                }
            }} exit={{
                opacity: 0,
                height: 0,
                transition: {
                    duration: .25
                }
            }}>{props.question}</motion.div>}</AnimatePresence>
            <div style={{position: 'relative', width: '100%', height: 58 - (condensed ? 28 : 0)}}>
                <div style={{
                    position: 'relative',
                    width: width + 16, //390,
                    height: 54 - (condensed ? 20 : 0),
                    background: checked ? checkedColorAlpha : uncheckedColorAlpha, //'#ffffff77',
                    borderRadius: props.name === 'PLZ' ? '0px 0px 8px 8px' : 0,
                    marginLeft: -8,
                    marginRight: -8,
                    top: 0
                }}/>
                <div style={{position: 'absolute', left: 8, top: 5}}>
                    {condensed && <CheckCircleIcon sx={{fontSize: 24, color: checkedColor}}/>}
                </div>
                <div style={{position: 'absolute', left: 40, top: 8}}>
                    {!condensed ? <Select
                        defaultValue={defaultValue}
                        id="combo-box-demo"
                        size={"small"}
                        style={{
                            background: '#ffffff', //checked ? '#ffffff' : '#ffffffbb',
                            borderRadius: 4,
                            width: width - 42
                        }}
                        inputProps={{
                            startAdornment: <span style={{
                                color: '#404040'
                            }}>{props.name + ": "}</span>
                        }}
                        startAdornment={<span style={{
                            color: '#404040',
                            marginLeft: -8
                        }}>{props.name + ": "}</span>}
                        onChange={
                            (event, child) => {
                                const value = event.target.value;
                                if (value) {
                                    setChecked(true);
                                    setSelected(value);
                                    setCondensed(true);
                                    if (props.set) props.set(props.name, value)
                                }
                                else setChecked(false);
                            }
                        }
                        onBlur={(e) => {
                            if (checked) setCondensed(true)
                        }}
                    >
                        {props.options.map((option) => <MenuItem value={option}>{option}</MenuItem>)}
                    </Select> : <div style={{position: 'absolute', left: 0, width: 264}}>
                        <div style={{color: '#00204060', marginTop: -1, fontSize: 16}}>{props.name + ": " + (defaultValue || selected)}</div>
                        {props.hint && <div style={{position: 'absolute', textAlign: 'right', right: 0, width: '100%', color: '#ff0000', marginTop: -2, fontSize: 16}}>{props.hint}</div>}
                    </div>}
                    </div>
            </div>
            {checked && selected !== "nein" && props.then}
        </motion.div>)
}

export default CheckedSelect/* () => <Autocomplete
        disablePortal
        blurOnSelect
        id="combo-box-demo"
        options={categories}
        sx={{width: 300}}
        renderInput={(params) => <TextField {...params} label="Kategorie"/>}
    />*/
