import React from 'react';
import './App.css';
import {Grid, SvgIcon, SvgIconProps} from "@mui/material";
import {Link} from "react-router-dom";
import {motion} from 'framer-motion';


const localURL = "http://localhost:3000";
const publicURL = "https://quickcheck.urbanistic.de"
const url = localURL

export function LogoIcon(props: SvgIconProps) {
    return (
        <SvgIcon {...props}>
            <g>
                <path d="M3.314,11.585c0.089,4,3.264,7.085,7.254,7.085c3.991,0,7.116-3.17,7.206-7.17l-0.024-7H14.5v7.002
		c0,2.023-1.978,3.669-4.001,3.669c-2.022,0-3.999-1.646-3.999-3.669V4.5H3.292L3.314,11.585z"/>
                <g>
                    <circle cx="19.88" cy="16.7" r="2.14"/>
                </g>
            </g>
        </SvgIcon>
    );
}

export function getVariable(key: string, url: string): string | null {
    let u = new URL(url);
    return u.searchParams.get(key);
}

export function getVariableOrNull(key: string): string | undefined {
    let result = getVariable(key, window.location.toString())
    if (result) return result
}

const cardStyle = {
    position: 'relative',
    width: 320, //390,
    height: 260,
    background: '#ffffff', //'#ffffff77',
    borderRadius: 8,
    padding: 12,
    top: 0
}

const cardStyleLight = {
    position: 'relative',
    width: 320, //390,
    height: 260,
    background: '#ffffff', //'#ffffff77',
    borderRadius: 8,
    padding: 12,
    paddingTop: 18,
    top: 0,
    boxShadow: '2px 2px 32px -4px #00204016'
} as React.CSSProperties;

export const textStyle = {
    color: '#00204060',
    fontSize: 16,
    lineHeight: 1.444,
} as React.CSSProperties;

export const textStyleSmall = {
    color: '#00204060',
    fontSize: 13,
    lineHeight: 1.444,
} as React.CSSProperties;

function Landing(props: {clickCheck: () => void, clickQR: () => void}) {
    const check = new Image();
    check.src = '01check_.jpg';
    const qr = new Image();
    qr.src = '02qr_.jpg';

    return (
        <div className="App">
            {/*getVariable(window.location.toString())*/}
            <Grid
                container
                spacing={0}
                direction="column"
                alignItems="center"
                //justifyContent="center"
                style={{minHeight: '100vh'}}
            >
                <Grid item xs={3} sx={{mb: -1}}>
                    <LogoIcon style={{color: '#ffffff', width: 72, height: 72}}/>
                </Grid>
                <Grid item xs={3} sx={{mb: 1}}>
                    <div onClick={props.clickCheck}>
                        <motion.div
                            style={{
                                ...cardStyleLight,
                                background: 'url(01check__.jpg)',
                                backgroundColor: '#ffffff',
                                backgroundSize: '378px 256px', //'344px 233px',
                                backgroundRepeat: 'no-repeat',
                                backgroundPosition: 'bottom -8px right -22px'
                            }} initial={{scale: 0}}
                            exit={{scale: 0}}
                            animate={{scale: 1, transition: {duration: .5, delay: .0}}}
                            whileTap={{scale: .9}}><span style={{
                            fontFamily: 'Montserrat',
                            fontWeight: 400,
                            fontSize: 21,
                            color: '#0050a8'
                        }}>Quick-Check</span>
                            <div style={{
                                position: 'absolute',
                                top: 60,
                                left: 20,
                                right: 160,
                                bottom: 20,
                                color: '#00204060',
                                fontSize: 16,
                                lineHeight: 1.444,
                                textAlign: 'left'
                            }}>Sie planen eine Baumaßnahme und möchten sich informieren, ob oder welche Art von
                                Bauantrag Sie benötigen? Hier sind Sie richtig!
                            </div>
                        </motion.div>
                    </div>
                </Grid>
                <Grid item xs={3}>
                    <div onClick={props.clickQR}>
                        <motion.div
                            style={{
                                ...cardStyleLight,
                                background: 'url(02qr__.jpg)',
                                backgroundColor: '#ffffff',
                                backgroundSize: '378px 256px', //'344px 233px',
                                backgroundRepeat: 'no-repeat',
                                backgroundPosition: 'bottom -8px right -22px'
                            }} initial={{scale: 0}}
                            exit={{scale: 0}}
                            animate={{scale: 1, transition: {duration: .5, delay: .2}}}
                            whileTap={{scale: .9}}><span style={{
                            fontFamily: 'Montserrat',
                            fontWeight: 400,
                            fontSize: 21,
                            color: '#0050a8'
                        }}>QR-Code</span>
                            <div style={{
                                position: 'absolute',
                                top: 60,
                                left: 20,
                                right: 160,
                                bottom: 20,
                                color: '#00204060',
                                fontSize: 16,
                                lineHeight: 1.444,
                                textAlign: 'left'
                            }}>Sie stellen ein Bauprodukt her und möchten Ihren Kunden zeigen, ob oder wann sie hierfür
                                eine Baugenehmigung benötigen? Generieren sie einen QR-Code!
                            </div>
                        </motion.div>
                    </div>
                </Grid>
                <Grid item xs={3} sx={{mt: 1, mb: 2}}><span
                    style={{color: '#ffffff', fontSize: 13}}>powered by<br/></span>
                    <img src={'urbanistic_logo.png'} width={501 / 5} height={83 / 5} style={{marginTop: 2}}/>
                </Grid>
            </Grid>
        </div>
    );
}

export default Landing;
