import React, {useEffect} from 'react';
import './App.css';
import QRCode from "react-qr-code";
import {Button, Grid, SvgIcon, SvgIconProps, TextField} from "@mui/material";
import Options, {CheckedOption} from "./components/Options";
import TextInput from "./components/TextInput";
import Info from "./components/Info";
import TileSelect, {checkedColor} from "./components/TileSelect";
import DimensionsInput from "./components/DimensionsInput";
import ShareIcon from '@mui/icons-material/Share';
import DownloadIcon from '@mui/icons-material/Download';
import EmailIcon from '@mui/icons-material/Email';
import QrCode2Icon from '@mui/icons-material/QrCode2';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';

const localURL = "http://localhost:3000";
const publicURL = "https://quickcheck.urbanistic.de"
const url = publicURL

function LogoIcon(props: SvgIconProps) {
    return (
        <SvgIcon {...props}>
            <g>
                <path d="M3.314,11.585c0.089,4,3.264,7.085,7.254,7.085c3.991,0,7.116-3.17,7.206-7.17l-0.024-7H14.5v7.002
		c0,2.023-1.978,3.669-4.001,3.669c-2.022,0-3.999-1.646-3.999-3.669V4.5H3.292L3.314,11.585z"/>
                <g>
                    <circle cx="19.88" cy="16.7" r="2.14"/>
                </g>
            </g>
        </SvgIcon>
    );
}

export function getVariable(key: string, url: string): string | null {
    let u = new URL(url);
    return u.searchParams.get(key);
}

export function getVariableOrNull(key: string): string | undefined {
    let result = getVariable(key, window.location.toString())
    if (result) return result
}

function App() {
    const [text, setText] = React.useState<string>("Hello World!");
    const [dictionary, setDictionary] = React.useState<{}>({});
    const [hints, setHints] = React.useState<{}>({});
    const [hasHints, setHasHints] = React.useState<boolean>(false);
    const [started, setStarted] = React.useState<boolean>(false);
    const [finished, setFinished] = React.useState<boolean>(false);

    const addHint = (name: string, h: string) => {
        let entry = {} as any
        entry[name] = h;
        setHints({...hints, ...entry});
    }
    const getHint = (name: string) => (hints as any)[name];
    const layout = {
        width: 320
    }
    const collect = {
        set: (key: string, value: string | undefined) => {
            const dict = dictionary
            let entry = {} as any
            entry[key] = value;
            setDictionary({...dict, ...entry})
            setStarted(true)
            setHints({})
            //setFinished(false)
        },
        condensed: finished,
        ...layout
    }

    const getURL = (url: string) => {
        let result = url + "/";
        let add = "?"
        for (var key in dictionary) {
            const value = (dictionary as any)[key];
            result += add + key + "=" + value
            if (add === "?") add = "&"
        }
        return result
    }

    const getValue = (key: string) => {
        const dict = dictionary
        const value = (dictionary as any)[key];
        return value
    }

    const ScrollerDummy = () => {
        useEffect(() => {
            console.log("scroll");
            window.scrollTo({top: 440, left: 0, behavior: 'smooth'});
        });

        return(<> </>)
    }

    const CheckListDisabledIcon = () => <div style={{position: 'absolute', width: 64, height: 64}}>
        <div style={{position: 'absolute', left: 24, top: 0}}><CheckBoxIcon sx={{fontSize: 24, color: '#ffffff'}}/></div>
        <div style={{position: 'absolute', left: 24, top: 24}}><CheckBoxOutlineBlankIcon sx={{fontSize: 24, color: '#ffffff'}}/></div>
        <div style={{position: 'absolute', left: 24, top: 48}}><CheckBoxOutlineBlankIcon sx={{fontSize: 24, color: '#ffffff'}}/></div>
    </div>

    return (
        <div className="App">
            {/*getVariable(window.location.toString())*/}
            <Grid
                container
                spacing={0}
                direction="column"
                alignItems="center"
                //justifyContent="center"
                style={{minHeight: '100vh'}}
            >
                <Grid item xs={3}>
                    <LogoIcon style={{color: '#ffffff', width: 72, height: 72}}/>
                </Grid>
                <Grid item xs={3}>
                    <TileSelect {...collect}
                        target={'Balkon'}
                        question={"Was planen Sie?"}
                        default={getVariableOrNull("c")}
                        name={"Kategorie"} unit={""}/*/>
                    <Options {...collect}
                        question={"Was planen Sie?"}
                        name={"Kategorie"}
                        default={getVariableOrNull("c")}
                        options={[
                            "Gartenhaus",
                            "Solaranlage",
                            "Sanierung",
                            "Balkon",
                            "Flughafen"]}*/
                        then={[
                            <TextInput {...collect}
                                question={"Geben Sie die Tiefe (Distanz von der vorderen Kante zur Hauskante) in Metern an:"}
                                name={"Tiefe"} unit={"m"} hint={getHint("Tiefe")}/>,
                            <TextInput {...collect}
                                question={"Geben Sie die Breite in Metern an:"}
                                name={"Breite"} unit={"m"} hint={getHint("Breite")}/>,
                            <Options {...collect}
                                question={"Geben Sie die Bauart des Balkons an:"}
                                name={"Bauart"}
                                options={[
                                    "Vorstellbalkon",
                                    "Anbaubalkon",
                                    "Nischenbalkon",
                                    "freitragender Balkon",
                                    "das weiß ich nicht"
                                ]}/>,
                            <Options {...collect}
                                     question={"Sind Sie Eigentümer der Immobilie, bei welcher der Balkon gebaut werden soll?"}
                                     name={"Eigentümer"}
                                     options={[
                                         "ja, zu 100%", "ja, unter 100%"]}/>,
                            <Options {...collect}
                                     question={"Steht die Immobilie unter Denkmalschutz?"}
                                     name={"Denkmalschutz"}
                                     options={[
                                         "ja", "nein", "ich weiß es nicht"]}
                                     then={[<Options {...collect}
                                                     question={"Um welche Art von Denkmalschutz handelt es sich?"}
                                                     name={"Art Denkmalschutz"}
                                                     options={[
                                                         "Ensembleschutz",
                                                         "Fassadenschutz",
                                                         "Quartiersschutz",
                                                         "Erhaltungssatzung"]}/>
                                     ]}/>,
                            <TextInput {...collect}
                                       question={"Geben Sie den Abstand der vordersten Kante des Balkons bis zur nächsten Grundstücksgrenze in Metern an:"}
                                       name={"Abstand"}
                                       hint={getHint("Abstand")}
                                       unit={"m"}/>,
                            <Options {...collect}
                                question={"Müssen zur Errichtung des Balkons Bäume gefällt werden?"}
                                name={"Baumfällung"}
                                hint={getHint("Baumfällung")}
                                options={[
                                    "ja", "nein"]}
                                then={[<TextInput {...collect}
                                    question={"Geben Sie den Stammdurchmesser des größten zu fällenden Baums in cm an:"}
                                    name={"Stammdurchmesser"}
                                    hint={getHint("Stammdurchmesser")}
                                    unit={"cm"}/>]}/>,
                            <CheckedOption {...collect}
                                question={"Geben Sie die Postleitzahl des Standortes an:"}
                                options={["34632 Jesberg",
                                    "34633 Ottrau",
                                    "34637 Schrecksbach",
                                    "34639 Schwarzenborn (Knüll)",
                                    "35037 Marburg",
                                    "35066 Frankenberg (Eder)",
                                    "35075 Gladenbach",
                                    "35080 Bad Endbach",
                                    "35083 Wetter (Hessen)",
                                    "35085 Ebsdorfergrund",
                                    "35088 Battenberg (Eder)",
                                    "35091 Cölbe",
                                    "35094 Lahntal",
                                    "35096 Weimar (Lahn)",
                                    "35099 Burgwald",
                                    "35102 Lohra",
                                    "35104 Lichtenfels (Hessen)",
                                    "35108 Allendorf (Eder)"]}
                                name={"PLZ"}
                                />
                        ]}/>
                </Grid>
                {started && <Grid item xs={3}>
                    <Button
                        onClick={(e) => {
                            setFinished(true);
                        }}
                        variant="contained"
                        disabled={!(getValue("PLZ"))}
                        style={{background: getValue("PLZ") ? checkedColor : '#00204020', marginTop: 16, marginBottom: 16, height: 88, width: 88, borderRadius: 44}}>
                        <span style={{
                            fontFamily: 'Montserrat',
                            fontWeight: 700,
                            fontSize: 17,
                            color: '#ffffff'
                        }}>CHECK</span>
                    </Button>
                </Grid>}
                {finished &&
                <>{!hasHints && <ScrollerDummy/>}<Grid item xs={3} sx={{mt: 1}}>
                    {parseFloat(getValue("Tiefe")) < 1.5 && <Info icon={'info'}>
                        <b>Genehmigungsfreies Bauvorhaben.</b> Dieses muss beim Bauamt anmeldet werden. Es müssen alle gesetzlichen Bestimmungen sowie die Vorgaben des Bebauungsplans eingehalten werden. Dabei ist der Bauherr für die Einhaltung verantwortlich.
                    </Info>}
                    {parseFloat(getValue("Tiefe")) >= 1.5 && <Info
                        onClick={() => {
                            addHint("Tiefe", "Anbauten unter einer Tiefe von 1,5m zählen als untergeordnete Bauteile. Diese Klassifizierung führt in der Regel zu einem einfacheren Genehmigungsprozess.");
                            window.scrollTo({top: 0, left: 0, behavior: 'smooth' });
                            setHasHints(true);
                        }}
                        icon={'info'}>
                        <b>Bauantrag erforderlich.</b> Bei Ihrem Balkon handelt es sich um ein genehmigungspflichtiges Bauprojekt, müssen Sie einen Bauantrag stellen.<br/>
                        <span style={{color: '#ff6f00', fontWeight: 'bold'}}>Hinweise ansehen</span>
                    </Info>}
                    {parseFloat(getValue("Tiefe")) < 1.5 && <Info icon={'house'}>
                        <b>Untergeordnetes Bauteil.</b> Diese Klassifizierung sagt aus, dass bei der Errichtung des Balkons keine
                        Abstandsflächen berücksichtigt werden müssen. Genauere Information finden Sie <span style={{fontWeight: 'bold', color: '#0088ff'}}>hier</span>.
                    </Info>}
                    {parseFloat(getValue("Abstand")) < 3 && <Info
                        onClick={() => {
                            addHint("Abstand", "Es gelten zusätzliche Bestimmungen, wenn der Abstand vom Bauteil zur Grundstücksgrenze weniger als 3m beträgt");
                            window.scrollTo({top: 0, left: 0, behavior: 'smooth' });
                            setHasHints(true);
                        }}
                        icon={'house'}>
                        <b>Grenzbebauung.</b>
                        Grundsätzlich bedarf diese der <b>Einverständnis des Nachbarn</b> oder die Genehmigung durch die Baubehörde. Sprechen Sie im Vorfeld mit den betroffenen Nachbarn!<br/>
                        <span style={{color: '#ff6f00', fontWeight: 'bold'}}>Hinweise ansehen</span>
                    </Info>}
                    {parseFloat(getValue("Tiefe")) < 1.5 && <Info icon={'file'}>
                        <b>Bauanzeige.</b> Was sind die nächsten Schritte? Bitte prüfen Sie im Vorfeld <span style={{fontWeight: 'bold', color: '#0088ff'}}>hier</span> nochmals die Details Ihres Bauvorhabens. Sie benötigen eine formlose Bauanzeige. Mit einer textlichen und idealerweise auch bildhaften Darstellung teilen Sie der Gemeinde oder der Stadt schriftlich mit, dass Sie eine bauliche Veränderung an einem Grundstück oder an einer Immobilie vornehmen möchten. Richten Sie die Bauanzeige an:
                    </Info>}
                    {parseFloat(getValue("Tiefe")) >= 1.5 && <Info icon={'file'}>
                        <b>Bauantrag.</b> Was sind die nächsten Schritte? Bitte prüfen Sie im Vorfeld <span style={{fontWeight: 'bold', color: '#0088ff'}}>hier</span> nochmals die Details Ihres Bauvorhabens.
                        genehmigungspflichtiges Bauprojekt, müssen Sie einen Bauantrag stellen. Folgende Unterlagen sollten dem Antrag beiliegen:
                        <ul>
                            <li>die Baubeschreibung</li>
                            <li>die vollständigen, vom Architekten oder zuständigen Handwerksmeister unterschriebenen Bauzeichnungen</li>
                            <li>die statische Berechnung des Balkons</li>
                            <li>der amtliche Lageplan</li>
                        </ul>
                        Richten Sie die Bauanzeige an:
                    </Info>}
                    <Info icon={'person'}>
                        <b>Ansprechpartner</b><br/>
                        Der Kreisausschuss des Schwalm-Eder-Kreises<br/>
                        Parkstraße 6, 34576 Homberg (Efze)<br/>
                        Telefon: 05681 775-0<br/>
                        Telefax: 05681 775-631<br/>
                        E-Mail: bauaufsicht@schwalm-eder-kreis.de
                    </Info>
                </Grid>
                    <Grid item xs={3} sx={{mt: 1, mb: 2}}><span
                    style={{color: '#ffffff', fontSize: 13}}>powered by<br/></span>
                    <img src={'urbanistic_logo.png'} width={501 / 5} height={83 / 5} style={{marginTop: 2}}/>
                    </Grid></>
                }
            </Grid>
        </div>
    );
}

export default App;


export function QR() {
    const [text, setText] = React.useState<string>("Hello World!");
    const [dictionary, setDictionary] = React.useState<{}>({});
    const [started, setStarted] = React.useState<boolean>(false);
    const [finished, setFinished] = React.useState<boolean>(false);
    const [generated, setGenerated] = React.useState<boolean>(false);

    const layout = {
        width: 320
    }
    const collect = {
        set: (key: string, value: string | undefined) => {
            const dict = dictionary
            let entry = {} as any
            entry[key] = value;
            setDictionary({...dict, ...entry})
            setStarted(true)
            //setFinished(false)
        },
        condensed: finished,
        ...layout
    }

    const getURL = (url: string) => {
        let result = url + "/";
        let add = "?"
        for (var key in dictionary) {
            const value = (dictionary as any)[key];
            result += add + key + "=" + value
            if (add === "?") add = "&"
        }
        return result
    }

    const getValue = (key: string) => {
        const dict = dictionary
        const value = (dictionary as any)[key];
        return value
    }

    const QRCodeCard = () => {
        useEffect(() => {
            console.log("scroll");
            window.scrollTo({top: 1200, left: 0, behavior: 'smooth' });
        });
        return <div style={{
            position: 'relative',
            width: '100%',
            height: '100%',
            top: 0,
            background: '#ffffff', //'linear-gradient(0.375turn, #f2f4f6, #b0c0d0)', //#e2e6eb, #e2e6eb)',
            textAlign: 'center',
            borderRadius: 8}}
        >
            <QRCode size={280} value={getURL(url)} style={{margin: 28}}/>
            <div style={{margin: 8, marginTop: 0}}>
                <Button variant="contained" style={{background: checkedColor, marginRight: 9, width: 88}}>
                    <EmailIcon/>
                </Button>
                <Button variant="contained" style={{background: checkedColor, marginRight: 9, width: 88}}>
                        <DownloadIcon/>
                    </Button>
                <Button variant="contained" style={{background: checkedColor, width: 88}}>
                    <ShareIcon/>
                </Button>
            </div>
            <TextField
                style={{
                    marginTop: 8,
                    marginBottom: 16,
                    width: 320 - 38,
                    background: '#ffffff',
                    borderRadius: 4
                }} //332
                size={"small"}
                value={getURL(url)}/>
        </div>
    }

    return (
        <div className="App">
            {/*getVariable(window.location.toString())*/}
            <Grid
                container
                spacing={0}
                direction="column"
                alignItems="center"
                //justifyContent="center"
                style={{minHeight: '100vh'}}
            >
                <Grid item xs={3}>
                    <LogoIcon style={{color: '#ffffff', width: 72, height: 72}}/>
                </Grid>
                <Grid item xs={3}>
                    <TileSelect {...collect}
                                target={'Gartenhaus'}
                                question={"Welches Produkt oder welchen Service bieten Sie an?"}
                                default={getVariableOrNull("c")}
                                name={"Kategorie"} unit={""}/*/>
                    <Options {...collect}
                        question={"Was planen Sie?"}
                        name={"Kategorie"}
                        default={getVariableOrNull("c")}
                        options={[
                            "Gartenhaus",
                            "Solaranlage",
                            "Sanierung",
                            "Balkon",
                            "Flughafen"]}*/
                                then={[
                                    <DimensionsInput {...collect}
                                               question={"Geben Sie Außenmaße in Metern an:"}
                                               name={"Maße"} unit={"m"}/>,
                                    <TextInput {...collect}
                                               question={"Geben Sie den Brutto-Rauminhalt in Kubikmetern an:"}
                                               name={"Raum"} unit={"m³"}/>,
                                    <Options {...collect}
                                             question={"Benötigt das Gartenhaus ein Fundament? Wenn ja, welches?"}
                                             name={"Fundament"}
                                             options={[
                                                 "nein",
                                                 "Bodenplatten",
                                                 "Streifenfundament",
                                                 "Pfahlfundament"
                                             ]}/>,
                                    <Options {...collect}
                                             question={"Planen Sie im Gartenhaus die Einrichtung eines Aufenthaltsraums (z.B. Arbeitszimmer, Küche, Bad, Schlafmöglichkeit)?"}
                                             name={"Aufenthaltsraum"}
                                             options={[
                                                 "ja", "nein"]}/>,
                                    <Options {...collect}
                                             question={"Planen Sie im Gartenhaus einen Wasseranschluss, z.B: für Toilette, Küche, oder Dusche?"}
                                             name={"Wasseranschluss"}
                                             options={[
                                                 "ja", "nein"]}/>,
                                    <Options {...collect}
                                             question={"Sind Sie Eigentümer des Grundstücks auf dem das Gartenhaus errichtet werden soll?"}
                                             name={"Eigentümer"}
                                             options={[
                                                 "ja, zu 100%", "ja, unter 100%"]}/>,
                                    <TextInput {...collect}
                                               question={"Geben Sie den Abstand des Gartenhauses nächsten Grundstücksgrenze in Metern an:"}
                                               name={"Abstand"} unit={"m"}/>,
                                    <Options {...collect}
                                             question={"Befindet sich der geplante Standort des Gartenhauses im Innen- oder Außenbereich Ihrer Gemeinde?"}
                                             name={"Gemeinde"}
                                             options={[
                                                 "Innenbereich", "Außenbereich"]}/>,
                                    <Options {...collect}
                                             question={"Geben Sie die Postleitzahl des Standortes an:"}
                                             options={["34632 Jesberg",
                                                 "34633 Ottrau",
                                                 "34637 Schrecksbach",
                                                 "34639 Schwarzenborn (Knüll)",
                                                 "35037 Marburg",
                                                 "35066 Frankenberg (Eder)",
                                                 "35075 Gladenbach",
                                                 "35080 Bad Endbach",
                                                 "35083 Wetter (Hessen)",
                                                 "35085 Ebsdorfergrund",
                                                 "35088 Battenberg (Eder)",
                                                 "35091 Cölbe",
                                                 "35094 Lahntal",
                                                 "35096 Weimar (Lahn)",
                                                 "35099 Burgwald",
                                                 "35102 Lohra",
                                                 "35104 Lichtenfels (Hessen)",
                                                 "35108 Allendorf (Eder)"]}
                                             name={"PLZ"}
                                    />
                                ]}/>
                </Grid>
                {started && <Grid item xs={3}>
                <Button
                    onClick={(e) => {
                        setGenerated(true);
                    }}
                    variant="contained"
                    style={{background: checkedColor, marginTop: 16, marginBottom: 16, height: 88, width: 88, borderRadius: 44}}>
                    <QrCode2Icon sx={{fontSize: 64, color: '#ffffff'}}/>
                </Button>
                </Grid>}
                {generated && <><Grid item xs={3}>

                    <div
                        style={{
                            width: 320 + 16,
                            marginLeft: -8,
                            marginRight: -8,
                            marginBottom: 16,
                            marginTop: 16}}>
                        <QRCodeCard/></div>
                </Grid>
                    <Grid item xs={3} sx={{mt: 1, mb: 2}}><span
                        style={{color: '#ffffff', fontSize: 13}}>powered by<br/></span>
                        <img src={'urbanistic_logo.png'} width={501 / 5} height={83 / 5} style={{marginTop: 2}}/>
                    </Grid></>}
            </Grid>
        </div>
    );
}
